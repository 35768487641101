<template>
  <header :data-theme="theme" :data-mobile="mobile" :class="{ 'menu-open': activeMenu !== null }">
    <div class="container container--fluid header-submenu-container">
      <div class="container container--bleed">
        <div class="row">
          <div class="header-submenu" v-if="config.header_submenu">
            <div class="header-submenu-item" v-for="(link, l) in config.header_submenu" :key="l" :class="{ 'is-parent': link.menu.length > 0 }" v-editable="link">
              <a :href="getLinkFieldUrl(link.link)" :target="link.link?.target" class="header-submenu-link" v-text="link.label"> </a>
              <template v-if="link?.menu?.length > 0">
                <div class="menu-box">
                  <a class="menu-box-link" :href="getLinkFieldUrl(submenu.link)" :target="submenu.link?.target" v-for="(submenu, s) in link.menu" :key="s" v-editable="submenu">
                    <div class="menu-box-label" v-text="submenu.label"></div>
                  </a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav nav-header role="navigation">
      <div class="container container--bleed">
        <div class="row row--full">
          <div class="brand">
            <a href="/" class="brand-link" title="Kudosity">
              <img :src="logo.filename" class="brand-logo" alt="Kudosity Logo" />
            </a>
          </div>
          <div class="menu">
            <div class="menu-items">
              <MenuTab :blok="menu" v-for="(menu, m) in props.config.header_menu" :key="m" :data-id="m" />
            </div>
          </div>
          <div class="cta">
            <template v-if="props.config.cta" class="nav-mobile-cta">
              <StoryblokComponent v-for="blok in props.config.cta" :key="blok._uid" :blok="blok" />
            </template>
            <button class="navbar-toggler" type="button" @click.prevent="_toggleNavMobile" aria-label="Toggle navigation"></button>
          </div>
        </div>
      </div>
    </nav>
    <MenuMobile :mobile="mobile" :config="config"></MenuMobile>
  </header>
</template>

<script setup>
import { nextTick } from "vue";
import { usePageStore } from "@/stores/page";
import MenuMobile from "./MenuMobile.vue";

const props = defineProps({
  config: {
    type: Object,
  },
});
const pageStore = usePageStore();

const activeMenu = ref(null);
const menuCloseTimeout = ref(null);

const mobile = ref(false);
const pageTheme = ref("dark");
const theme = computed(() => {
  if (activeMenu.value !== null) return "light";
  return pageTheme.value;
});

watch(
  pageStore,
  async (state) => {
    await nextTick();
    pageTheme.value = state.header_theme;
    if (document) document?.querySelector("header")?.setAttribute("data-theme", state.header_theme);
  },
  { deep: true }
);

const _toggleNavMobile = () => {
  mobile.value = !mobile.value;
};

const logo = computed(() => {
  if (mobile.value || theme.value === "light") {
    return props.config.header_logo_dark;
  }
  return props.config.header_logo;
});

const onMenuHover = (event) => {
  const index = event.target.getAttribute("data-id");
  activeMenu.value = index;
  clearTimeout(menuCloseTimeout.value);
};

const onMenuHoverOut = (event) => {
  menuCloseTimeout.value = setTimeout(() => {
    activeMenu.value = null;
  }, 50);
};

onMounted(() => {
  document.querySelectorAll("[nav-header] .menu-items > .menu-item").forEach((menu) => {
    menu.addEventListener("mouseenter", onMenuHover);
    menu.addEventListener("mouseleave", onMenuHoverOut);
  });

  if (window?.location?.hash) {
    try {
      const target = document.querySelector(window.location.hash);
      if (target) {
        target.scrollIntoView({
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
});
</script>
